import { useState, useEffect } from 'react'

import Loader from '../components/Loader/Component';
import Table from '../components/Table/Component';


const Settings = () => {

    const [getLoaded, setLoading] = useState('Loading')
    const [tableRows, setTableRows] = useState([])
    const [tableCols, setTableCols] = useState([])



    const options = {
        itemsPerPage: 5,
        target: '/subject',
        actions : {
            edit: {
                perms: [1],
            },
            delete: {
                perms: [1],
            },
        }
    }

    
    let formInputs = [
        {
            name: 'name',
            displayName: 'Subject Name',
            max: 200,
            type: 'text',
            col: 100,
        },
    ]
    
    useEffect(() => {
        let token = localStorage.getItem('token')
        const fetchData = async () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },

            }


            await fetch(`${process.env.REACT_APP_API_URL}/api/subject/list`, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response.status === "success") {
                        console.log(response)
                        let data = response.data
                        setTableCols(data.cols)
                        setTableRows(data.rows)
                        setLoading()
                    }else{
                        console.log('An error occured.')
                    }
                })
        }
        fetchData()
    }, [])


  

    return (
        <div className="pageContainer">
            {
                !getLoaded ? 
                <div className="pageWrapper">
                    <Table title="Subjects" data={tableRows} options={options} columns={tableCols} formInputs={formInputs} />
                </div>
                : <Loader text={getLoaded} />
            }
        </div>
    )
}

export default Settings
