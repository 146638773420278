import useAuth from "./useAuth";

const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {
        
        const token = localStorage.getItem('token')
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },

            body: JSON.stringify({token})
        }
        await fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, requestOptions)
            .then(response => setAuth({}))
            .then(data => localStorage.removeItem('token'))
    }

    return logout;
}

export default useLogout