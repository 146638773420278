import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import {useNavigate, useLocation } from 'react-router-dom';


const Login = () => {
    const { setAuth, setToken, auth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();

    const [mail, setMail] = useState('');
    const [password, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');


    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [mail, password])

  
    const authFetch = (data) => {
        if (data.status === 'success'){
            const accessToken = data.token
            const role = data.role;
            const id = data.id
            setAuth({id, mail, role, accessToken });
            setMail('');
            setPwd('');
            setToken(accessToken)
            localStorage.setItem("token", accessToken);
            navigate(from, { replace: true });
        }else{
            setErrMsg(data.message)
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },

            body: JSON.stringify({ mail, password})
        }
        await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, requestOptions)
            .then(response => response.json())
            .then(data => authFetch(data));
    }

    


    return (

        <section className="loginSection">
            <form className="loginForm" onSubmit={handleSubmit}>
                <div className="title">Int-Exam</div>
                <input
                    className="loginInput"
                    type="text"
                    id="username"
                    placeholder="Username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setMail(e.target.value)}
                    value={mail}
                />
                <input
                    className="loginInput"
                    type="password"
                    id="password"
                    placeholder="Password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={password}
                />
                {<span className="errorMessage">{errMsg}</span>}
                <button className="signIn">Sign In</button>
            </form>
        </section>

    )
}

export default Login
