import { useState, useEffect } from 'react'
import Loader from '../components/Loader/Component'
import Table from '../components/Table/Component'
import Card from '../components/Card/Component'

import {useNavigate} from 'react-router-dom'


import { useParams } from "react-router-dom"


const ExamResults = () => {

    const [getLoaded, setLoading] = useState('Loading')
    const [tableRows, setTableRows] = useState([])
    const [tableCols, setTableCols] = useState([])
    const [totalAttempted, setTotalAttempted] = useState('')


    const { id } = useParams()

     



    useEffect(() => {
        let token = localStorage.getItem('token')
        const fetchData = async () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
            await fetch(`${process.env.REACT_APP_API_URL}/api/exam/${id}/results`, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response.status === "success") {
                        let data = response.data

                        let {resultCols, resultRows, totalAttempted} = data
                        setTableCols({resultCols})
                        setTableRows({resultRows})
                        setTotalAttempted(parseInt(totalAttempted))
                        setLoading()
                    }else{
                        console.log('An error occured.')
                    }
                })
        }

        const intervalId = setInterval(() => {
            fetchData()
        }, 10000);

        

        return () => {
            clearInterval(intervalId);
        };
    }, [])



    return (
        <div className="pageContainer">
            {
                !getLoaded ? 
                <div className="pageWrapper">
                
                    <div className="infoCard">
                        <div className="infoCardTitle">
                            {totalAttempted}
                        </div>
                        <div className="infoCardCount">
                            TOTAL ATTEMPTED
                        </div>
    
                    </div>

                    <Table title="Live Results" data={tableRows.resultRows} itemsPerPage={5} columns={tableCols.resultCols} options={{itemsPerPage: 20, hidden: [12]}}/>
                </div>
                : <Loader text={getLoaded} />
            }
        </div>
    )
}

export default ExamResults
