import { useNavigate, Link } from 'react-router-dom'

import { React } from 'react'
import './sidebar.css'

import useLogout from '../../hooks/useLogout'


import Icon from '../Icon/Component.jsx'

const Sidebar = ({uri}) => {

    const logout = useLogout();

    const navigate = useNavigate();

    const signOut = async () => {
        await logout();
        navigate('/login');
    }



    const header = [
        {
            name: 'Dashboard',
            icon: 'chart',
            uri: 'dashboard',
        },
        {
            name: 'Exams',
            icon: 'document',
            uri: 'exams',
        },
        {
            name: 'Settings',
            icon: 'settings',
            uri: 'settings',
        }
    ]
    return (
        <section className="row">
            <div className="header">
            </div>
            <div className="sidebar">
                <div className="logoText"><Link to="/" className="logoLink">INT-EXAM ADMIN PANEL</Link></div>
                <div className="logoSeperator" />
                {
                    header.map((route, id) => {
                        let active = route.uri===uri
                        return <Link key={id} className={`sidebarItem ${active && `active`}`} to={'../'+route.uri}>
                                    <div className="sidebarIcon" to={route.uri}>
                                        <Icon
                                            name={route.icon}
                                            width={20}
                                            height={20}
                                            stroke="2"
                                            fill={active ? `#ff` : `#4FA2D1`}
                                        />
                                    </div>
                                    <span className="sidebarText">{route.name}</span>
                                </Link>
                    })
                }
                <button className='logOutAdmin'  onClick={() => signOut()}>Log Out</button>
            </div>
        </section>
    );
}

export default Sidebar