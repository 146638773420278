import { useState, useEffect } from 'react'
import Loader from '../components/Loader/Component'
import Table from '../components/Table/Component'
import Card from '../components/Card/Component'

import {useNavigate} from 'react-router-dom'


import { useParams } from "react-router-dom"


const Exams = () => {

    const [getLoaded, setLoading] = useState('Loading')
    const [tableRows, setTableRows] = useState([])
    const [tableCols, setTableCols] = useState([])
    const [subjects, setSubjects] = useState([])
    const [answerKey, setAnswerKey] = useState('')


    const { id } = useParams()

    const navigate = useNavigate()
     
    const options = {
        itemsPerPage: 5,
        target: '/question',
        actions : {
            edit: {
                perms: [1],
            },
            delete: {
                perms: [1],
            },
            show: {
                perms: [1],
                click: (id) =>  navigate(`/questions/${id}/`)
            }, 
        }
    }


    let formInputs = [
        {
            name: 'sort',
            displayName: 'Question Id',
            type: 'number',
            col: 15,
        },
        {
            name: 'answer',
            displayName: 'Answer(s)',
            type: 'select',
            col: 20,
            multiple: true,
            options: {
                'A':'Alpha',
                'B':'Bravo',
                'C':'Charlie',
                'D':'Delta',
                'E':'Echo',
            }
        },
        {
            name: 'point',
            displayName: 'Point',
            type: 'integer',
            col: 15,
        },
        {
            name: 'subject',
            displayName: 'Subject',
            type: 'select',
            col: 20,
            options: subjects
        },
        {
            name: 'status',
            displayName: 'Status',
            type: 'select',
            col: 15,
            options: {
                0: 'Active',
                1: 'Cancelled',
            },
        },
        {
            name: 'type',
            displayName: 'Type',
            type: 'select',
            col: 15,
            options: {
                0: 'Question',
                1: 'Paragraph',
            },
        },
        {
            name: 'question',
            displayName: 'Question',
            type: 'file',
            col: 100,
        },
        {
            name: 'id',
            type: 'hidden',
            value: id
        },
    ]

    let excelInputs = [

        {
            name: 'id',
            type: 'hidden',
            value: id
        },

        {
            name: 'title',
            displayName: 'Title',
            max: 100,
            type: 'text',
            col: 100,
        },
        {
            name: 'users',
            displayName: 'Excel File',
            type: 'file',
            col: 100,
        },

    ]

    useEffect(() => {
        let token = localStorage.getItem('token')
        const fetchData = async () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
            await fetch(`${process.env.REACT_APP_API_URL}/api/exam/${id}`, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response.status === "success") {
                        let data = response.data

                        let {questionCols, resultCols, questionRows, resultRows, subjects, answerKey, uploadedCols, uploadedRows} = data
                        setTableCols({questionCols, resultCols, uploadedCols})
                        setTableRows({questionRows, resultRows, uploadedRows})
                        setSubjects(subjects)
                        setAnswerKey(answerKey)
                        setLoading()
                    }else{
                        console.log('An error occured.')
                    }
                })
        }
        fetchData()
    }, [])



    return (
        <div className="pageContainer">
            {
                !getLoaded ? 
                <div className="pageWrapper">
                    <Table title="Questions" data={tableRows.questionRows} itemsPerPage={5} columns={tableCols.questionCols} options={options} formInputs={formInputs}  />
                    <Table title="Excel Users" data={tableRows.uploadedRows} itemsPerPage={5} columns={tableCols.uploadedCols} options={{itemsPerPage: 10, target: '/userlist', actions : { delete: { perms: [1] }}}} formInputs={excelInputs} />
                    <Table title="Results" data={tableRows.resultRows} itemsPerPage={5} columns={tableCols.resultCols} options={{export: true, itemsPerPage: 10, hidden: [12]}}/>
                    <Card title="Answer Key" body={
                        <div>
                           {answerKey}
                        </div>
                    }/>
                </div>
                : <Loader text={getLoaded} />
            }
        </div>
    )
}

export default Exams
