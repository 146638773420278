import { useState, useEffect } from 'react'
import Card from '../components/Card/Component'
import StaticTable from '../components/StaticTable/Component'

import { useParams } from "react-router-dom"



const UsersInner = () => {
    const { id } = useParams()

    const [resultCols, setResultCols] = useState([])
    const [resultRow, setResultRow] = useState([])

    const [answers, setAnswers] = useState([])

    useEffect(() => {
        let token = localStorage.getItem('token')
        const fetchData = async () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
            await fetch(`${process.env.REACT_APP_API_URL}/api/user/result/${id}`, requestOptions)
                .then(response => response.json())
                .then(response => {
                    console.log('response', response)
                    const {cols, examResult, answers} = response.data
                    setResultCols(cols)
                    setResultRow(examResult)
                    setAnswers(answers)
                })
        }
        fetchData()
    }, [])
    const columnCount = Math.ceil(answers.length/20);
    return (
        <div className="pageContainer">
            <Card body={
                <StaticTable cols={resultCols} rows={resultRow} />
            } />
            <Card title="Answers" body={
                <div>
                    <div style={{ display: 'grid', gridTemplateColumns: `repeat(${columnCount})`}}>
                        {
                            answers.map((a, id) => (
                                <div key={id}>
                                    <div className="optionRow">  
                                        <div className="questionId">{a.id}</div>
                                        {
                                            ['A','B','C','D','E'].map(option => (
                                                //${a.useranswer==option ? `wrongAnswer` : ``}
                                                <div className={`option-result ${a.answer.includes(option) && `correctAnswer`} ${(a.useranswer===option  && !a.answer.includes(option)) && `wrongAnswer`} ${a.useranswer===option && `choosenAnswer`}`}>{option}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                 </div>
            } />

         
           
        </div>
    )
}

export default UsersInner