import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from '../hooks/useAuth';

const PersistLogin = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const { setAuth, auth } = useAuth();

    const authFetch = async () => {
        const localToken = localStorage.getItem('token');
        if (localToken){
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({localToken})
            }
    
    
            const resp = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/refresh`, requestOptions).then(response => response.json())
            if (resp.status=='success'){
                const userId = resp.user.id
                const user = resp.user.name
                const role = resp.user.role
                const accessToken = localToken
                const userName = resp.user.userName
                setAuth({id: userId, user, role, accessToken, userName});
                
            }else{
                localStorage.removeItem('token');
                navigate('/login');
            }
            setIsLoading(false)
        }else{
            setIsLoading(false)
            navigate('/login');
        }
    } 


    useEffect(() => {
        authFetch()
    }, [isLoading])

    return (
        <>
            {isLoading
                ? <div className="centerFlex"><div className="loader"></div></div>
                : <Outlet />
            }
        </>
    )
}

export default PersistLogin