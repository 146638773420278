import { useState, useEffect } from "react"
import {useNavigate} from 'react-router-dom'

import Loader from '../components/Loader/Component';
import Table from '../components/Table/Component';




const Dashboard = () => {

    const [getLoaded, setLoading] = useState('Loading')
    const [tableRows, setTableRows] = useState([])
    const [tableCols, setTableCols] = useState([])
    const [exams, setExams] = useState([])

    const navigate = useNavigate()


    const options = {
        itemsPerPage: 10,
        target: '/user',
        actions : {
            edit: {
                perms: [1],
            },
            delete: {
                perms: [1],
            },
            show: {
                perms: [1],
                click: (id) =>  navigate(`/users/${id}`)
            }, 
        }
    }


    let formInputs = [
        {
            name: 'name',
            displayName: 'Name',
            max: 100,
            type: 'text',
            col: 33,
        },
        {
            name: 'username',
            displayName: 'Username',
            max: 30,
            type: 'text',
            col: 33,
            placeHolder: '88888800000'
        },
        {
            name: 'password',
            displayName: 'Password',
            max: 30,
            type: 'password',
            col: 33,
        },
        {
            name: 'exam',
            displayName: 'Exam',
            type: 'select',
            col: 33,
            options: exams
        },
        {
            name: 'role',
            displayName: 'Role',
            type: 'select',
            col: 33,
            options: {
                0: 'Visitor',
                1: 'Admin',
            }
        },
        {
            name: 'status',
            displayName: 'Account Status',
            type: 'select',
            col: 33,
            options: {
                1: 'Active',
                0: 'Banned'
            }
        }
    ]
    
    useEffect(() => {
        let token = localStorage.getItem('token')
        const fetchData = async () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },

            }

            await fetch(`${process.env.REACT_APP_API_URL}/api/user/list`, requestOptions)
                .then(response => response.json())
                .then(response => {
                    console.log(response)
                    if (response.status === "success") {
                        let data = response.data
                        setTableCols(data.cols)
                        setTableRows(data.rows)
                        setExams(data.exams)
                        setLoading()
                    }else{
                        console.log('An error occured.')
                    }
                })
        }
        fetchData()
    }, [])


  

    return (
        <div className="pageContainer">
            {
                !getLoaded ? 
                <div className="pageWrapper">
                    <Table title="Users" data={tableRows} options={options} columns={tableCols} formInputs={formInputs} />
                </div>
                : <Loader text={getLoaded} />
            }
        </div>
    )
}

export default Dashboard