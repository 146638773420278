import '../admin/css/style.css';
import Sidebar from '../components/sidebar/Sidebar';

const Admin = ({uri, page}) => {
  return (
    <div>
      <Sidebar uri={uri} />
      {page}
    </div>
  );
}

export default Admin;