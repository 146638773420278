import { useState, useEffect } from 'react'
import Card from '../components/Card/Component'
import StaticTable from '../components/StaticTable/Component'

import { useParams } from "react-router-dom"

const GivenAnswer = ({option, color, percent}) => {
    return <div className="givenAnswerWrapper">
        <div className="answerOption" style={{backgroundColor: color}}>{option}</div>
        <div className="givenAnswerPercent" style={{backgroundColor: color, flex: [percent/100]}} />
    </div>
}

const StatCard = ({title, quantity, color}) => {
    return <div className="statCard" style={{backgroundColor: color}}>
        <div>{title}</div>
        <div>{quantity}</div>
    </div>
}

const QuestionInner = () => {
    const { id } = useParams()

    const [question, setQuestion] = useState([])
    const [questionDetails, setQuestionDetails] = useState([])

    const [options, setOptions] = useState([])

    useEffect(() => {
        let token = localStorage.getItem('token')
        const fetchData = async () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
            await fetch(`${process.env.REACT_APP_API_URL}/api/question/stats/${id}`, requestOptions)
                .then(response => response.json())
                .then(response => {
                    const {question, options} = response.data
                    const row = Object.values(question);
                    setQuestionDetails(row.slice(0, row.length - 6))
                    setQuestion(question)
                    setOptions(options)
                })
        }
        fetchData()
    }, [])
    return (
        <div className="pageContainer">



            <Card body={
                <StaticTable cols={['Id', 'Question', 'Answer(s)', 'Subject', 'Type']} rows={questionDetails} />
            } />

            <Card width={30} body={
                <img className="fitImage" src={question.url}/>
            } />

            <Card title="Given Answers" width={40} body={
                <div className="givenAnswerContainer">
                    {/*
                        Object.entries({A: '#EF476F', B: '#FFD166', C: '#06D6A0', D: '#118AB2', E: '#073B4C'}).map(([option, color]) => (
                            <GivenAnswer key={option} option={option} color={color} percent={options[option]?.percent}/>
                        ))
                    */}
                    {
                        ['A','B','C','D','E'].map((option, index) => (
                            <GivenAnswer key={option} option={option} color={`#${questionDetails[2]?.includes(option) ? '06D6A0' : 'EF476F'}`} percent={options[option]?.percent}/>
                        ))
                    }
                </div>
            } />
            <Card title="Statistics" width={30} body={
                <div>
                    <StatCard title="Answer Rate" quantity={question.answerRate} color="#118AB2"/>
                    <StatCard title="Correct Answer Rate" quantity={question.correctRate} color="#06D6A0"/>
                    <StatCard title="Wrong Answer Rate" quantity={question.wrongRate} color="#EF476F" />
                    <StatCard title="Total Answers" quantity={question.totalAnswers} color="#073B4C" />
                </div>
           
            } />
        </div>
    )
}

export default QuestionInner
