import './index.css'
import Login from './pages/Login'
import Home from './pages/Home'
import Exam from './pages/Exam'
import ExamInner from './admin/ExamInner'
import ExamResults from './admin/ExamResults'
import Dashboard from './admin/Dashboard'
import Layout from './pages/Layout'
import Missing from './pages/Missing'
import RequireAuth from './pages/RequireAuth'
import PersistLogin from './pages/PersistLogin'
import { Routes, Route } from 'react-router-dom'
import useAuth from "./hooks/useAuth"
import Admin from './layout/Admin'
import Exams from './admin/Exams'
import QuestionInner from './admin/QuestionInner'
import UsersInner from './admin/UsersInner'
import Settings from './admin/Settings'


const Perms = {
  user: 0,
  admin: 1
}



function App() {

  const { auth } = useAuth()
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />

        {/* persist login must be handled as it was. */}
        <Route element={<PersistLogin />}>

          <Route path="/" element={(auth?.role===Perms.admin) ? <Admin uri="dashboard" page={<Dashboard />} /> : <Home />} />
          
          <Route element={<RequireAuth allowedRoles={[Perms.user]} />}>
            <Route path="exam" element={<Home />} />
            <Route path="exam/:id" element={<Exam />} />
            <Route path="/home" element={<Home />} />
          </Route>

          
          <Route element={<RequireAuth allowedRoles={[Perms.admin]} />}>
            <Route path="dashboard" element={<Admin uri="dashboard" page={<Dashboard />} />} />
            <Route path="exams" element={<Admin uri="exams" page={<Exams />}/>} />
            <Route path="exams/:id" element={<Admin uri="exams" page={<ExamInner />}/>} />
            <Route path="exams/:id/results" element={<Admin uri="exams" page={<ExamResults />}/>} />
            <Route path="questions/:id" element={<Admin uri="exams" page={<QuestionInner />}/>} />
            <Route path="users/:id" element={<Admin uri="dashboard" page={<UsersInner />}/>} />
            <Route path="settings" element={<Admin uri="settings" page={<Settings />}/>} />
          </Route>
        </Route>
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  )
}

export default App