import React, { useState, useEffect } from 'react'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import Modal from '../../components/Modal/Component'
import Form from '../../components/Form/Form'
import Icon from '../../components/Icon/Component'


import useAuth from "../../hooks/useAuth"

import './style.css';

const Table = ({ title, data, columns, formInputs, options }) => {
  const [tableData, setTableData] = useState(data);


  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(options?.itemsPerPage ?? 10)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortColumn, setSortColumn] = useState('')
  const [showCreationForm, setShowCreationForm] = useState(false)
  const [showEditForm, setShowEditForm] = useState()
  const [sortDirection, setSortDirection] = useState('asc')

  

  const [currentItems, setCurrentItems] = useState([])

  const [deleteId, setDeleteId] = useState()
  const [formMessage, setFormMessage] = useState('')
  const [specify, setSpecify] = useState([])

  


  const { auth } = useAuth()

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSortColumn('');
    setSortDirection('asc');
  }

  const handleItemsPerPageChange = (event) => {
    const selectedItemsPerPage = parseInt(event.target.value)
    setItemsPerPage(selectedItemsPerPage)
    setCurrentPage(1)
  }

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase()
    setSearchTerm(searchValue)
    setCurrentPage(1)
  }


  let sortedData = [...tableData];

  if (sortColumn) {
    sortedData.sort((a, b) => {
      const valueA = a[columns.indexOf(sortColumn)];
      const valueB = b[columns.indexOf(sortColumn)];
  
      if (valueA < valueB) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  const filteredData = sortedData.filter((item) => {
    const searchTerms = searchTerm.toLowerCase().split(' ');
    return searchTerms.every((term) =>
      columns.some((column, index) =>
        item[index]?.toString().toLowerCase().includes(term)
      )
    );
  });

  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPageNumbers = () => {
    const maxPageNumbers = 4;
    const pageNumbers = [];

    if (totalPages <= maxPageNumbers) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const rangeStart = Math.max(1, currentPage - 2);
      const rangeEnd = Math.min(totalPages, rangeStart + maxPageNumbers - 1);

      if (rangeStart > 1) {
        pageNumbers.push(1, '');
      }

      for (let i = rangeStart; i <= rangeEnd; i++) {
        pageNumbers.push(i);
      }

      if (rangeEnd < totalPages) {
        pageNumbers.push('', totalPages);
      }
    }

    return pageNumbers;
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  useEffect(() => {
    setCurrentItems(filteredData.slice(startIndex, endIndex))
  }, [startIndex, endIndex, sortDirection, sortColumn]);

  const token = localStorage.getItem('token')
  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
  
  const handleExport = () => {
    const wb = XLSX.utils.book_new()

    const ws = XLSX.utils.aoa_to_sheet(data)

    XLSX.utils.book_append_sheet(wb, ws, title)

    const excelBuffer = XLSX.write(wb, { type: 'array' })
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    saveAs(blob, `${title}.xlsx`)
  }


  const handleColumnSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  }

  const handleDelete = async (id) => {
    await fetch(`${process.env.REACT_APP_API_URL}/api${options.target}/delete/${id}`, requestOptions)
      .then(response => response.json())
      .then(response => {
        if (response.status==='success'){
          setTableData(currentItems => currentItems.filter((row) => row[0] !== id))
          setDeleteId()
          setCurrentItems(currentItems => currentItems.filter((row) => row[0] !== id))
        }
      })
  }

  const handleCreateNew = () => {
    formInputs.forEach((input) => {
      if (input.name != 'id'){
        input.value = null
      }
    })
    setSpecify()
    setFormMessage()
    setShowCreationForm(true)
  };
  

  const handleEdit = async (value) => {
    let editFormInputs = formInputs.slice();
    editFormInputs.push({
      name: 'id',
      displayName: 'ID',
      type: 'hidden',
      value
    })

    await fetch(`${process.env.REACT_APP_API_URL}/api${options.target}/edit/${value}`, requestOptions)
      .then(response => response.json())
      .then(response => {
        editFormInputs.forEach((input, index) => {
          if (index < editFormInputs.length - 1) {
            let editinfo = response.data.row;
            input.value = editinfo[input.name]  ?? ''
          }
        })
      })

    setSpecify()
    setFormMessage()
    setShowEditForm(editFormInputs)
  }

  const handleUpdateRow = (resp) => {
    if (resp.status==='success'){
      const row = resp.data
      setCurrentItems(currentItems => {
        return currentItems.map((item) => {
          if (item[0] == row.id) {
            return [...Object.values(row)];
          }
          return item
        })
      })

      setTableData(tableData => {
        return tableData.map((item) => {
          if (item[0] == row.id) {
            return [...Object.values(row)];
          }
          return item
        })
      })

      setShowEditForm(false)
    }else{
      setSpecify(resp.message.errors?.inputs)
      setFormMessage(resp.message.errors?.message)
    }
  }

  const handleAddRow = (resp) => {
    if (resp.status === 'success'){
      const row = resp.data
      setCurrentItems(current => {
        return [row, ...current]
      })

      setTableData(current => {
        return [row, ...current]
      })
      setShowCreationForm(false)
    }else{
      setSpecify(resp.message.errors?.inputs)
      setFormMessage(resp.message.errors?.message)
    }
  }
  
  const actionFuncs = {
    edit: (id) => handleEdit(id),
    delete: (id) => setDeleteId(id),
  }

  const isImageLink = (cell) => {
    const imageLinkRegex = /\.(jpeg|jpg|gif|png|bmp|webp)$/i;
    return imageLinkRegex.test(cell);
  }

  return (
    <div className="tableContainer">
      {showCreationForm && <Modal 
          title="Creation Form"
          body={
              <div className="formWrapper">
                <Form inputs={formInputs} target={`/api${options.target}/create`} success={(row) => handleAddRow(row)} message={formMessage} specify={specify}/>
              </div>
          }
          cancel={() => setShowCreationForm(false)}
          cancelText="close"
      />}
      {showEditForm && <Modal 
          title="Edit Form"
          body={
              <div className="formWrapper">
                <Form inputs={showEditForm} target={`/api${options.target}/update`} success={(row) => handleUpdateRow(row)} message={formMessage} specify={specify}/>
              </div>
          }
          cancel={() => setShowEditForm(false)}
          cancelText="close"
      />}
      {
        deleteId && <Modal 
          title="Confirm Deletion"
          body={
            <div className="">
              You are about to <b>delete</b> a record with id <b>{deleteId}</b>.
            </div>
          }
          confirm="Yes, Delete"
          action={() => handleDelete(deleteId)}
          cancel={() => setDeleteId()}
        />
      
      }
      <div className="tableHeader">
          <div className="tableTitle">{title}</div>
          {<div className="tableOptions">
            {formInputs &&  <div className="createNewButton" onClick={() => handleCreateNew()}>Create New</div>}
            {options?.export &&  <div className="exportButton" onClick={() => handleExport()}>Export</div>}
            <select value={itemsPerPage} onChange={handleItemsPerPageChange} className="tableItems">
                <option value="5">5 Rows</option>
                <option value="10">10 Rows</option>
                <option value="20">20 Rows</option>
                <option value={totalItems}>All Rows</option>
            </select>
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              className="tableSearch"
            />
          </div>}
      </div>
      <table className="table">
        <thead>
          <tr>
            {columns.map((column, id) => {
              if (!options?.hidden?.includes(id)){
                return <th
                  key={column}
                  onClick={() => handleColumnSort(column)}
                  className={sortColumn === column ? sortDirection : ''}
                >
                {column}
                {sortColumn === column && (
                  sortDirection === 'asc' ? '↑' : '↓'
                )}

              </th>
              }
            })}
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? currentItems.map((item, index) => (
            <tr key={index}>
              {columns.map((column, index) => {
                if (!options?.hidden?.includes(index)){
                  return <td key={index}>
                    <span className="tableMobile">{column}</span>
                    {!isImageLink(item[index]) ? item[index] : <img src={item[index]} className="tableLogo"/>}
                  </td>
                }
              })}
              {options?.actions && <td><div className="actionButtons">
                {
                  Object.entries(options.actions).map(([action, actionData], index) => {
                    const canRender = actionData.perms.includes(auth?.role)
                    if (canRender) {
                      return (
                        <Icon
                          key={index}
                          name={action}
                          className={`${action}Button`}
                          stroke={2}
                          color="#fff"
                          fill="none"
                          onClick={() => (actionData.click ? actionData.click(item[0]) : actionFuncs[action](item[0]))}
                        />
                      );
                    }
                  })
                }
              </div></td>}
            </tr>
          )) : <tr><td colSpan={columns.length} className="tableEmpty">There is no data for this table.</td></tr>}
        </tbody>
      </table>
      {totalPages !== 0 && <div className="tableFooter">
        <div>Showing {currentItems.length} of {totalItems} rows.</div>
        <div className="tablePagination">
            {
              currentPage !== 1 && 
              <button onClick={() => handlePageChange(currentPage - 1)}>≪</button>
            }
            {
              getPageNumbers().map((pageNumber, index) => ((pageNumber !== '') ? 
                <button 
                  key={index}
                  onClick={() => handlePageChange(pageNumber)}
                  style={pageNumber === currentPage ? { backgroundColor: '#f4eefd' } : {}}
                >{pageNumber}</button>
                : <div key={index} >...</div>
              ))
            }
            { 
              (currentPage !== totalPages) &&
              <button onClick={() => handlePageChange(currentPage + 1)}>≫</button>
            }
        </div>
      </div>}
    </div>
  );
};

export default Table
