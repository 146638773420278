import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import Modal from '../components/Modal/Component'

const Exam = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const token = localStorage.getItem('token')

    const [isLoaded, setLoaded] = useState(false)
    const [questionList, setQuestionList] = useState([])
    const [questionCount, setQuestionCount] = useState()
    const [currentQuestion, setCurrentQuestion] = useState({})
    const [answer, setAnswer] = useState()
    const [examLength, setExamLength] = useState()
    const [stats, setStats] = useState()
    //const [deadLine, setDeadLine] = useState()
    const [examName, setExamName] = useState()
    const [userId, setUserId] = useState()
    const [userName, setUserName] = useState()
    const [modalOpen, setModalOpen] = useState(false)


    useEffect(() =>  {
        const intervalId = setInterval(() => {
            /*if (Math.round(new Date().getTime()/1000) % 60 == 0){
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                }
                fetch(`${process.env.REACT_APP_API_URL}/api/exam/stats/rank`, requestOptions)
                    .then(response => response.json())
                    .then(res => {
                        setStats(res.data)
                    })                   
            }*/
            setExamLength(prev => prev - 1);
        }, 1000);
    
        return () => {
            clearInterval(intervalId);
        };
    }, []);


    useEffect(() => {
        const dataFetch = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
            await fetch(`${process.env.REACT_APP_API_URL}/api/exam/${id}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'success' && data.exam.completed != 1){
                        setExamName(data.exam.title)
                        setUserId(data.user)
                        setUserName(data.userName)
                        setQuestionCount(data.questions.length)
                        let questions = []
                        for (let index = 0; index < data.questions.length; index++) {
                            questions.push({id: index+1, 'answer': data.answers?.find(x => x.question === index+1)?.answer, 'question' : `${process.env.REACT_APP_API_URL}/uploads/${data.questions[index].url}`, type: data.questions[index].type})
                        }

                        //iterate answers and push yani.
                        setQuestionList(questions)

                        setCurrentQuestion(questions[0])

                        setAnswer(questions[0].answer)

                        //yourdeadline
                        setExamLength(Math.round(data.deadLine-data.current))

                        setLoaded(true)
                    }else{
                        navigate('/')
                    }
                })
        }
        dataFetch()
    }, [])

    const getTime = (deadLine) => {
        if (deadLine <= 0){
            navigate('/');
        }
        const minutes = Math.floor(deadLine/60)
        const seconds = deadLine % 60
        return deadLine ? `${minutes} min ${seconds} sec` : 'min sec';
    };

    const handleChange = async (e) => {
        let studentAnswer = e.currentTarget.value
        setAnswer(studentAnswer);


        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id, question: currentQuestion.id, studentAnswer})
        }

        await fetch(`${process.env.REACT_APP_API_URL}/api/exam/answer`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success'){
                    setQuestionList(questionList.map(q => {
                        return (q.id === currentQuestion.id) ? {...q, answer: studentAnswer}  : q;
                    }));
                }
            })
   
    
        //saveonapi...
    };

    function changeQuestion(dir){
        let questionSet;
        if (dir ===1){
            if (currentQuestion.id !== questionCount){
                questionSet = questionList[currentQuestion.id]
            }
        } else if (currentQuestion.id !== 1){
            questionSet = questionList[currentQuestion.id-2]
        }

        if (questionSet){
            setCurrentQuestion(questionSet)
            setAnswer(questionSet.answer)
        }
    }

    function focusQuestion(focus){
        let questionSet = questionList[focus];
        setCurrentQuestion(questionSet)
        setAnswer(questionSet.answer)
    }

    const endExam = async () => {

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            //body: JSON.stringify({ id, })
        }

        await fetch(`${process.env.REACT_APP_API_URL}/api/exam/${id}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success'){
                    navigate('/');
                }
            })
    }

    return isLoaded ? (
        <section>
            <div className="container">
                <div className="leftpane">
                </div>
                <div className="centerpane">
                    <div className="toppane">Welcome {userName}, you are now attending {examName}</div>
                    {false && <div className="toppane">{stats && `Your rank is ${stats.rank} out of ${stats.total} people. (Refreshes every 300 seconds.)`}</div>}
                    <div className="npButtons">
                        <div className="pButton" onClick={() => changeQuestion(-1)}>≪</div>
                        <div className="cButton">{currentQuestion.id}/{questionCount}</div>
                        <div className="nButton" onClick={() => changeQuestion(1)}>≫</div>
                    </div>
                    <img className="question" alt="question" src={currentQuestion.question}/>
                    {
                        ((currentQuestion.type===0) ? 
                        <div className="questionOptions">
                            {
                                ['A', 'B', 'C', 'D', 'E', 'X'].map(item => (
                                    <label key={item}>
                                        <div className={`questionOption ${(answer === item) ? 'selectedQuestionOption' : ''}`}>{item!='X' ? item : 'Empty'}</div>
                                        <input
                                            type='radio'
                                            value={item}
                                            onChange={handleChange}
                                            checked={answer === item}
                                        />
                                    </label>
                                ))
                            }
                        </div>
                    : <></>)
                    }
                    
                </div>
                <div className="rightpane">
                    <div className="timeLeft">
                        <div className="timeLeftTitle">Time Remain</div>
                        <div className="timeLeftMin">{getTime(examLength)}</div>
                    </div>

                    <div className="opticalForm">
                        {
                            questionList?.slice(currentQuestion.id-((currentQuestion.id <= 5) ? currentQuestion.id : 5), currentQuestion.id+((currentQuestion.id <= 5) ? 10-currentQuestion.id : 5)).map(item => (
                                <div key={`answer_${item.id}`} className="options">
                                    <div className={`numberOption ${(currentQuestion.id===item.id) ? `activeQuestion` : ``}`} onClick={() => focusQuestion(item.id-1)}>{item.id}</div>
                                    { ((item.type===0) ? 
                                        ['A','B','C','D','E'].map(option => (
                                            <div key={`option${item.id}`+option} className={`letterOption ${((item.answer===option) ? 'selectedLetter' : '')}`}>{option}</div>
                                        ))
                                        : <div className="paragraph">Paragraph</div>)
                                        
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className="endExam" onClick={() => setModalOpen(true)}>
                        <div className="endExamTitle">Finish Attempt</div>
                    </div>
                </div>
            </div>
            {(modalOpen) ? <Modal title={<strong>Are you sure to finish this attempt?</strong>} confirm="Yes, finish." cancel={() => setModalOpen(false)} action={() => endExam()} /> : <></>}
        </section>
    ) : ( <div className="centerFlex">
    <div className="loader"></div>
  </div>)
}

export default Exam


