import { useState, useEffect } from 'react'
import {useNavigate} from 'react-router-dom'

import Loader from '../components/Loader/Component';
import Table from '../components/Table/Component';


const Users = () => {

    const [getLoaded, setLoading] = useState('Loading')
    const [tableRows, setTableRows] = useState([])
    const [tableCols, setTableCols] = useState([])

    const navigate = useNavigate()


    const options = {
        itemsPerPage: 5,
        target: '/exam',
        actions : {
            edit: {
                perms: [1],
            },
            delete: {
                perms: [1],
            },
            show: {
                perms: [1],
                click: (id) =>  navigate(`/exams/${id}`)
            }, 
        }
    }

    
    let formInputs = [
        {
            name: 'title',
            displayName: 'Exam Name',
            max: 200,
            type: 'text',
            col: 70,
        },
        {
            name: 'length',
            displayName: 'Exam Length (Mins)',
            max: 200,
            type: 'integer',
            col: 30,
        },
        {
            name: 'min',
            displayName: 'Min Point',
            max: 20,
            type: 'integer',
            col: 30,
        },
        {
            name: 'max',
            displayName: 'Max Point',
            max: 20,
            type: 'integer',
            col: 30,
        },
        {
            name: 'cancel',
            displayName: 'Cancel',
            type: 'select',
            col: 40,
            options: {
                0: 'None',
                1: '1',
                2: '2',
                3: '3',
                4: '4',
            },
        },
        {
            name: 'starttime',
            displayName: 'Start Time',
            type: 'time',
            col: 20,
        },
        {
            name: 'startdate',
            displayName: 'Start Date',
            type: 'date',
            col: 30,
        },
        {
            name: 'endtime',
            displayName: 'End Time',
            type: 'time',
            col: 20,
        },
        {
            name: 'enddate',
            displayName: 'End Date',
            type: 'date',
            col: 30,
        }
    ]
    
    useEffect(() => {
        let token = localStorage.getItem('token')
        const fetchData = async () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },

            }


            await fetch(`${process.env.REACT_APP_API_URL}/api/exam/list`, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if (response.status === "success") {
                        let data = response.data
                        setTableCols(data.cols)
                        setTableRows(data.rows)
                        setLoading()
                    }else{
                        console.log('An error occured.')
                    }
                })
        }
        fetchData()
    }, [])


  

    return (
        <div className="pageContainer">
            {
                !getLoaded ? 
                <div className="pageWrapper">
                    <Table title="Exams" data={tableRows} options={options} columns={tableCols} formInputs={formInputs} />
                </div>
                : <Loader text={getLoaded} />
            }
        </div>
    )
}

export default Users
