import React, { useState, useEffect } from 'react'
import './style.css'


const SelectInput = ({ name, displayName, options, col, onChange, value, specify, multiple }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([])

  const handleOptionClick = (selected) => {
    setSelectedOptions((selectedOptions) =>
    selectedOptions.includes(selected)
        ? selectedOptions.filter((option) => option !== selected)
        : [...selectedOptions, selected]
    );
  };

  useEffect(() => {
    if (multiple){
      setSelectedOptions(Array.isArray(value) ? value : [])
    }
  }, [])


  useEffect(() => {
    let value = Array.isArray(selectedOptions) ? JSON.stringify(selectedOptions) : value
    onChange({ target: { name, value}})
  }, [selectedOptions])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  if (multiple){
    return (
      <div className="formContainer" style={{ width: `${col}%` }}>
        <div className="formElement">
          <label htmlFor={name}>{displayName}</label>
          <div className={`multiple-select`} onClick={toggleDropdown}>
            <div className="selected-values">
              {selectedOptions.length > 0 ? (
                Object.entries(options).filter(([optionId]) => selectedOptions.includes(optionId)).map(([optionId, option]) => (
                  <div key={optionId} className="selected-option">
                    {option}
                  </div>
                ))
              ) : (
                <div>Please Select</div>
              )}
            </div>
            {isOpen && (
              <div className="multiple-options">
                {Object.entries(options).map(([optionId, option]) => (
                  <div
                    key={optionId}
                    className={`option ${selectedOptions.includes(optionId) ? "selected" : ""}`}
                    onClick={() => handleOptionClick(optionId)}>
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }else{
    return (
      <div className="formContainer" style={{ width: `${col}%` }}>
        <div className="formElement">
          <label htmlFor={name}>{displayName}</label>
          <select className="formInput" style={specify ? { borderColor: 'red' } : {}} id={name} onChange={onChange} name={name} defaultValue={value ?? undefined}>
            <option value={undefined}>Please Select</option>
            {Object.entries(options).map(([optionId, option]) => (
              <option key={optionId} value={optionId}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }
}

const RadioInput = ({ name, displayName, options, col, onChange }) => (
  <div className="formContainer" style={{ width: `${col}%` }}>
    <div className="formElement">
      <label htmlFor={name}>{displayName}</label>
      {
        Object.entries(options).map(([optionId, option]) => (
          <div key={optionId}>
            <input
              className="formInput"
              type="radio"
              name={name}
              id={`${name}-${optionId}`}
              value={optionId}
              onChange={onChange}
            />
            <label htmlFor={`${name}-${optionId}`}>{option}</label>
          </div>
        ))
      }
    </div>
  </div>
);

const DefaultInput = ({ name, displayName, type, col, onChange, max, value, specify, placeHolder }) => (
  type !== 'hidden' ? (
    <div className="formContainer" style={{ width: `${col}%` }}>
      <div className="formElement">
        <label htmlFor={name}>{displayName}</label>
        <input className="formInput" style={specify ? { borderColor: 'red' } : {}}  name={name} id={name} maxLength={max} type={type} defaultValue={value} placeholder={placeHolder} onChange={onChange} />
      </div>
    </div>
  ) : (<input className="formInput" name={name} id={name} maxLength={max} type={type} defaultValue={value} onChange={onChange} />)

);

const Form = ({ target, inputs, success, message, specify, kvkk}) => {

  const [formData, setFormData] = useState({})
  const [saveButton, setSaveButton] = useState('Send')
  const [kvkkMessage, setKvkkMessage] = useState('');

  
  const handleFormSubmit = (e) => {
    setSaveButton('Sending')
    setKvkkMessage()
    e.preventDefault()

  
    const token = localStorage.getItem('token')
    const sendFormData = new FormData()

    Object.entries(formData).forEach(([key, value]) => {
      sendFormData.append(key, value)
    })

   
    if (kvkk && formData.kvkk || !kvkk){
      const requestOptions = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        },
        body: sendFormData
      }

      fetch(`${process.env.REACT_APP_API_URL}${target}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          success(data)
          setSaveButton('Send')
        })
        .catch((error) => {
          setSaveButton('Send')
          console.error(error)
        })
    }else{
      setKvkkMessage('Please accept KVKK to proceed.')
      setSaveButton('Send')
    }
  }

  const handleInputChange = (e) => {
    let { name, value, type, files } = e.target
    value = (type==='file') ? files[0] : value

    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  useEffect(() => {
    inputs.forEach((input) => {
      if (input.value || input.value===0) {
        setFormData((prevData) => ({ ...prevData, [input.name]: input.value }));
      }
    });
  }, [inputs]);

  return (
    <form onSubmit={handleFormSubmit} action={target}>
      <div className="formWrapper">
        {inputs.map((input, id) => {
          const { type } = input
          const key = `${id}-${input.name}`
          const specified = specify?.includes(input.name)
          switch (type) {
            case 'select':
              return <SelectInput key={key} onChange={handleInputChange} specify={specified} {...input} />;
            case 'radio':
              return <RadioInput key={key} onChange={handleInputChange} specify={specified} {...input} />;
            default:
              return <DefaultInput key={key} onChange={handleInputChange} specify={specified} {...input} />;
          }
        })}
        {kvkk && <label>
          <input
            type="checkbox"
            value="x"
            name="kvkk"
            onChange={handleInputChange}
          />
          I read and accept <a href={kvkk} target="_blank">KVKK</a>.
        </label>}
      </div>
      <div className="formText">{kvkkMessage}</div>
      <div className="formText">{message}</div>
      <button type="submit" className="saveButton" disabled={saveButton!=='Send'}>{saveButton}</button>
    </form>
  )
}

export default Form;
