import { React } from "react"
import "./style.css";


export default function Modal(props) {
    return (<div className="modalBackground">
                <div className="modalDialog">
                    <div className="modalTitle">{props.title}</div>
                    {props.body}
                    <div className="modalButtons">
                        {props.cancel && <button className="modalDeny" onClick={props.cancel}>Close</button>}
                        {props.confirm && <button className="modalAccept" onClick={props.action} autoFocus>{props.confirm}</button>}
                    </div>
                </div>
            </div>);
}