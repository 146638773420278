import { useNavigate } from 'react-router-dom'

import { useState, useEffect } from 'react'
import useLogout from '../hooks/useLogout'
import useAuth from '../hooks/useAuth'


const Home = () => {

    const [exams, setExams] = useState([])

    const [examStatus, setExamStatus] = useState([])




    const navigate = useNavigate();
    const logout = useLogout();
    const { auth } = useAuth()

    const signOut = async () => {
        await logout();
        navigate('/login');
    }

    
    useEffect(() => {
        const token = localStorage.getItem('token')
        const dataFetch = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }
            await fetch(`${process.env.REACT_APP_API_URL}/api/exam/all`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setExams(data.exams.map(exam => {
                        let status = getExamStatus(exam)
                        return {...exam, status}
                    }))
                })
        };
        dataFetch();
    },[])

    const getExamStatus = (exam) => {
        
        
        
        if (exam.current >= exam.startsat && exam.current <= exam.endsat){
            let totalTimeLeft = exam.endsat-exam.current;
     
            let userExamTime = (totalTimeLeft > exam.length ? exam.length : ((!exam.started_at) ? (exam.endsat-exam.current) : (exam.endsat-exam.started_at)))
            let userDeadLine = (exam.started_at ??  exam.current)  + userExamTime

            if ((exam.current < userDeadLine)){
                return (exam.completed != 1) ? <div className='examButton' style={{backgroundColor: '#7fed7d'}} onClick={() => navigate(`/exam/${exam.id}`)}>Attempt Exam</div> : <div className='examButton' style={{backgroundColor: '#43bbd4'}}>Completed</div>;
            }
        }

        const timeOut = ((exam.startsat-exam.current)*1000)

 
        if (timeOut > 0){
            setTimeout(() => {
                setExams((exams) => {
                    return exams.map((prev) => {
                      if (prev.id === exam.id) {
                        let status = getExamStatus({...exam, current: exam.startsat})
                        return { ...prev, status}
                      }
                      return prev
                    })
                  })
            }, timeOut)
        }
 
        return <div className='examButton' style={{backgroundColor: '#b5b5b5'}}>Inactive</div>;
    }

    return (
        <section>
            <div className='container'>
                <div className='examList'>
                    <div className='profileInfo'>Welcome to Int-Exam {auth.userName}. <div className='logOut' onClick={() => signOut()}>Log Out</div></div>
                    {exams?.map(item => (
                        <div key={item.id} className='examCard'>
                            <div className='examTitle'>{item.title}</div>
                            <div className='examCardBottom'>
                                {item.status}
                                <div className='examCardTime'>
                                    <div className='examTime'>{item.time}</div>
                                    <div className='examDate'> {item.examdate}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Home
