import { React } from 'react'
import './style.css'



const StaticTable = ({ cols, rows }) => {
    return (
        <div className="tableContainer">
            <table className="table">
                <thead>
                    <tr>
                        {
                            cols.map((col, index) => {
                                return <th key={index}>{col}</th>
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {
                            rows.map((row, index) => {
                                return <td key={index}>{row}</td>
                            })
                        }
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default StaticTable
