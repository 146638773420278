import { React } from 'react'
import './style.css'



const Component = ({ title, body, width }) => {
    width = width ? width-=2 : null
    return (
        <div className="card" style={{width: `${(width) ?? 98}%`}}>
            {title && <div className="cardTitle">{title}</div>}
            <div className="cardBody">
                {body}
            </div>
        </div>
    )
}

export default Component
